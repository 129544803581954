/* @font-face {
    font-family: 'American Typewriter Itc T OT';
    src: url('./assets/fonts/AmericanTypeItcTOT-Medi.woff2') format('woff2'),
        url('./assets/fonts/AmericanTypeItcTOT-Medi.woff') format('woff'),
        url('./assets/fonts/AmericanTypeItcTOT-Medi.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Futura Std';
    src: url('./assets/fonts/FuturaStd-CondensedBold.woff2') format('woff2'),
        url('./assets/fonts/FuturaStd-CondensedBold.woff') format('woff'),
        url('./assets/fonts/FuturaStd-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
	margin: 0;
	font-family: 'Futura Std', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}