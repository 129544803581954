$font-primary: "Futura Std", sans-serif;

:root {
  --color-text: #000;
  --color-primary: #999999;
}

html,
body {
  margin: 0;
  padding: 0;
  // overflow: hidden;
  background: #fff;
  color: var(--color-text);
  font-family: $font-primary;

  font-size: 16px;
}

@media (min-width: 700px) {
  html,
  body {
    font-size: 200%;
  }
}

@media (min-width: 700px) {
  html,
  body {
    font-size: 200%;
  }
}

h1 {
  font-size: 2.2rem;
  color: var(--color-primary);
  text-transform: uppercase;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

h3 {
  font-size: 0.8rem;
  text-transform: uppercase;
}

a, a:visited, a:active {
  color: var(--color-primary);
  transition: color 0.5s ease;
}

a:hover {
  color: var(--color-text)
}

p {
  line-height: 1.2 !important;
}

button {
  appearance: none;
  background: transparent;
  height: 50px;
  padding: 0 0.8rem;
  width: auto;
  width: 90px;
  // font-size: 0.85rem;
  font-size: 13px;
  border: 3px var(--color-primary) solid;
  color: var(--color-text);
  font-family: $font-primary;
  text-transform: uppercase;
  cursor: pointer;
}

button:hover {
  background: var(--color-primary);
}

input {
  appearance: none;
  background: transparent;
  height: 50px;
  padding: 0 0.8rem;
  border: 3px var(--color-primary) solid;
  color: var(--color-text);
  font-family: $font-primary;
  text-transform: uppercase;
  border-radius: 0;
  width: 250px;
  max-width: 100%;
}

input, input:active, input:focus, input:focus-within, input:target, input:hover, input:visited, input:focus-visible {
  outline: none;
}

.App {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

.App__Loading {
  text-transform: uppercase;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Header__Logo {
  width: 16rem;
  max-width: 60%;
  display: block;
  margin: 0 auto 40px;
}

.Info {
  margin: 20px 0;
}

.Events {
  margin-top: 3rem;
}

.Events__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  h1 {
    margin-right: 10px;
  }
}

.Event__Soldout {
  background: transparent;
  height: 50px;
  padding: 0;
  // text-align: center;
  width: auto;
  width: 90px;
  // font-size: 0.85rem;
  font-size: 13px;
  // border: 3px var(--color-primary) solid;
  color: var(--color-primary);
  font-family: $font-primary;
  text-transform: uppercase;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.4;
}

.Footer__Copyright {
  // font-size: 0.5rem;
  // text-transform: uppercase;
}

.Footer__Links {
  // font-size: 0.5rem;
  // text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-end;


  a {
    color: #fff;
    text-decoration: none;
  }
}

.MuiTableRow-root:last-child > * {
  border: none;
}

.MuiTableRow-root .MuiTableCell-body {
  &:first-child, &:last-child {
    padding: 6px 0 !important;
  }
  // th {
  //   padding: 6px 0 !important;
  // }
}

.Imprint, .Privacy {
  margin-bottom: 100px;

  p {
    font-size: 0.8rem;
    margin-bottom: 40px;
  }
}